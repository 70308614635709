@keyframes bg {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 200%;
  }
  100% {
    background-size: 100%;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  font-size: 18px;
}

body {
  margin: 0 10px 10px 10px;
  font-family: Trebuchet MS, monospace;
  font-display: fallback;
  line-height: 35px;
  overflow-x: hidden;
  max-width: 100vw;
  font-display: swap;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  letter-spacing: 1px;
}

a {
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Trebuchet MS, monospace;
  margin: 0 0 10px 0;
  line-height: initial;
}

@media (max-width: 800px) {
  body {
    -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
    margin:0
  }
}

@media (min-width: 800px) {
  html {
    font-size: 22px;
  }

  header img {
    max-height: 100px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 32px;
  }

  h1,
  h2, 
  h3 {
    line-height: 45px;
  }

}

.logo:not(.loader) {
  filter:grayscale(1);
}